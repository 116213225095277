import { ActionCard, ActionCardContent } from 'components/action-card'
import { CheckIcon, RightAngleIcon, TrophyIcon } from 'components/icons'
import { Inline } from 'components/layout'
import { Text } from 'components/Text'
import { useDateFormat } from 'hooks/useDateFormat'
import { ApiTypes } from 'lib/api'
import { useTranslate } from 'lib/i18n/useTranslate'
import React from 'react'
import { useRouteMatch } from 'react-router-dom'
import styled from 'styled-components/macro'
import { NBSP } from 'utils/styleUtils'
import { useLocalisedQuizValue } from './quizHelpers'

interface SingleQuizResultProps {
    result: ApiTypes['quiz-result']
}

export const SingleQuizResult: React.FC<SingleQuizResultProps> = ({ result }) => {
    const t = useTranslate()
    const { formatDate } = useDateFormat('long')
    const { translateQuizLocaleString } = useLocalisedQuizValue()
    const { path } = useRouteMatch()

    return (
        <ActionCard type="link" to={`${path}/${result.session}`}>
            <SingleQuizResultContent>
                <Inline spacing={4} display="flex" alignItems="center">
                    {result.finalRank === 1 || result.finalRank === 2 || result.finalRank === 3 ? (
                        <Icon placement={result.finalRank} as={TrophyIcon} />
                    ) : (
                        <Icon placement={result.finalRank ?? 0} as={CheckIcon} />
                    )}
                    <div>
                        <Placement>
                            <span style={{ fontWeight: 800 }}>{result.finalRank}</span>
                            <span>
                                {NBSP}/{NBSP}
                            </span>
                            <span>{result.nParticipants}</span>
                        </Placement>
                        <Small>{t('quiz::placement')}</Small>
                    </div>
                </Inline>
                <TitleWrap>
                    <Title>{translateQuizLocaleString(result.name, result.nameKeys)}</Title>
                    <Small>{formatDate(new Date(result.createdAt || ''))}</Small>
                </TitleWrap>

                <div>
                    <RightAngleIcon />
                </div>
            </SingleQuizResultContent>
        </ActionCard>
    )
}

const trophyColors = {
    1: '#ffd700',
    2: '#cad8e0',
    3: '#ff9900',
}

const SingleQuizResultContent = styled(ActionCardContent)`
    display: grid;
    grid-template-columns: auto 1fr auto;
    align-items: center;
    grid-column-gap: ${props => props.theme.spacing(4)};
    height: 100%;
    text-decoration: none;
`

export const Title = styled(Text)`
    font-weight: 600;
    line-height: 1.1;
`

export const Small = styled(Text).attrs(() => ({
    variant: 'caption',
}))``

const Icon = styled.div<{ placement: number }>`
    color: ${props =>
        props.placement in trophyColors
            ? trophyColors[props.placement as 1 | 2 | 3]
            : props.theme.palette.success.main};
    font-size: 2rem;
`

const Placement = styled.div`
    font-size: ${props => props.theme.typography.body1.fontSize};
    line-height: 1.1;
`

const TitleWrap = styled.div`
    padding-left: ${props => props.theme.spacing(4)};
    border-left: solid 1px ${props => props.theme.palette.divider};
`
